import React, {
    useMemo,
    useContext
} from 'react';
import {
    Div,
    P,
    BlankLink,
    A
} from '../../../hybrid/wrappers';
import {
    handleClick,
    getItemMenu
} from '../helpers';
import UserContext from '../../../common/utils/getContext';


const FooterMenu = ({ styles, navigation }) => {
    const {
        userToken, setUserToken, lang, menu
    } = useContext(UserContext);

    const footerMenu = useMemo(() => getItemMenu(menu?.footer, lang), [menu?.footer, lang]);

    return (
        <>
            {
                footerMenu.map((elem, i) =>
                    <Div styles={i === 0 ? styles.footerMenuItemWrapperMainFirst : styles.footerMenuItemWrapperMain} key={`accordionMob${i}`}>

                        {elem.submenu.map((el, idx) =>
                            <Div
                                styles={styles.footerAccordionBodyItemMain}
                                effects={{}}
                                key={`accordionMobItem${idx}`}
                            >
                                {
                                    el.type === 'outLink'
                                        ?
                                        <P styles={styles.officeMenuItemTextP}>
                                            <BlankLink
                                                styles={styles.itemMenuStyle}
                                                effects={{
                                                    hover: {color: styles.variable.darkColor}
                                                }}
                                                title={el.title}
                                                href={`https://${el.asLink}`}
                                                target={el?.target
                                                    ? '_blank'
                                                    : '_self'}
                                            />
                                        </P>

                                        :
                                        el.type === 'link'
                                            ?
                                            <A
                                                styles={styles.itemMenuStyleMainFooter}
                                                effects={{
                                                    hover: styles.itemMenuStyleMainFooterHover
                                                }}
                                                target={el?.target
                                                    ? '_blank'
                                                    : '_self'}
                                                title={el.title}
                                                href={`/${lang}${el.asLink}`}
                                            />
                                            : <P data-uitest='658_uitest' onClick={(e) => handleClick(
                                                e, el, lang, navigation, () => {
                                                }, setUserToken, userToken
                                            )}>{el.title}</P>
                                }
                            </Div>)}
                    </Div>)
            }
        </>
    );
};
export default FooterMenu;