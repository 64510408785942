import { media } from '../../../common/utils/utils';
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    footerAppButtonText: {
        color: 'inherit',
        ...uikit.subtitle,
        'line-height': '1',
        'font-weight': 500,
        'white-space': 'nowrap'
    },
    breadcrumb: media(width, height, 'w', {
        1726: {
            width: '1704px',
            display: 'flex',
            'align-items': 'center',
            padding: '14px 0 5px'
            // 'padding-left': '20px'

        },
        1500: {
            width: '1504px',
            display: 'flex',
            'align-items': 'center',
            padding: '14px 0 5px'
            // 'padding-left': '20px'

        },
        768: {
            width: '100%',
            display: 'flex',
            'align-items': 'center',
            'padding': '8px 16px'
            // 'padding-left': '40px'

        },
        0: {
            width: '100%',
            display: 'flex',
            'align-items': 'center',
            'padding': '0 12px',
            'padding-left': '20px'

        }
    }),
    breadItem: {
        display: 'flex',
        'align-items': 'center',
        'margin-right': '10px',
        color: variable.blueColor,
        ...uikit.small,
        cursor: 'pointer'
    },
    breadItemActive: { color: variable.greySuperDarkColor },
    breadItemTitle: media(width, height, 'w', {
        768: {
            'margin-right': '10px',
            cursor: 'pointer'
        },
        0: { 'margin-left': '10px' }
    }),
    mainWrapper: {
        'flex-grow': '1',
        display: 'flex',
        'flex-direction': 'column'
    },
    mainWrapperWeb: {
        background: `${variable.mainWrapperWebBackground}, url(../../../../src/img/zendo_2_bg.jpg)`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center'
        // 'transition': 'background 2s'
    },
    mainWrapperWebLogin: { background: `${variable.mainWrapperWebBackground}, url(../../../../src/img/zendo_2_bg.jpg)` },
    mainWrapperWebRegister: { background: `${variable.mainWrapperWebRegisterBackground}, url(../../../../src/img/zendo_2_bg.jpg)` },
    mainWrapperWebRestore: { background: `${variable.mainWrapperWebRestoreBackground}, url(../../../../src/img/zendo_2_bg.jpg)` },
    mainContainerWeb: media(width, height, 'w', {
        768: {
            'max-width': variable.containerWidth,
            margin: '0 auto',
            width: '100%',
            'min-height': '100%',
            display: 'flex',
            'flex-direction': 'column',
            'flex-grow': '1'
        },
        0: {
            'max-width': variable.containerWidth,
            margin: '0 auto',
            width: '100%',
            padding: '0',
            height: '100%',
            display: 'flex',
            'flex-direction': 'column'
        }
    }),
    mainContainerWebMainWrap: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        'z-index': '1'
    },
    mainContainerWebMain: media(width, height, 'w', {
        1568: {
            'max-width': variable.containerWidthMain,
            margin: '0 auto',
            width: '100%',
            'min-height': '100%',
            display: 'flex',
            'flex-direction': 'column',
            'flex-grow': '1',
            padding: '0 15px'
        },
        1200: {
            'max-width': '1200px',
            margin: '0 auto',
            width: '100%',
            'min-height': '100%',
            display: 'flex',
            'flex-direction': 'column',
            'flex-grow': '1',
            padding: '0 15px'
        },
        0: {
            'max-width': variable.containerWidthMain,
            margin: '0 auto',
            width: '100%',
            height: '100%',
            display: 'flex',
            'flex-direction': 'column',
            padding: '0 15px'
        }
    }),
    mainContainerWebMainFooter: media(width, height, 'w', {
        1568: { 'max-width': variable.containerWidthMain },
        1200: { 'max-width': '1200px' },
        0: { 'max-width': variable.containerWidthMain }
    }),
    blockXl: media(width, height, 'w', {
        1726: {
            'width': '100%',
            'max-width': '1600px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding': '0 30px'
        },
        1500: {
            'width': '100%',
            'max-width': '1560px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding': '0 30px'
        },
        768: {
            'max-width': '100%',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding': '0 30px'
        },
        460: {
            'max-width': '100%',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding': '0 30px'
        },
        0: {
            'width': '100%',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'padding': '0 15px'
        }
    }),
    blockXlMain: media(width, height, 'w', {
        1567: {
            'max-width': '1545px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            padding: '0 15px'
        },
        0: {
            'max-width': '100%',
            'margin-left': 'auto',
            'margin-right': 'auto',
            padding: '0 15px'
        }
    }),
    flexColumn: { 'flex-direction': 'column' },
    headerShadow: { 'box-shadow': `0px 5px 15px ${variable.blackExtraTransparentColor}` },
    mainHeader: media(width, height, 'w', {
        1536: {
            display: 'flex',
            padding: '16px 0'
        },
        0: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-end',
            padding: '8px 0px',
            'min-height': '60px'
        }
    }),
    mainHeaderMain: media(width, height, 'w', {
        1536: {
            display: 'flex',
            padding: '16px 0'
        },
        0: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-end',
            padding: '12px 0'
        }
    }),
    mainMenuWrapper: {
        width: '100%',
        display: 'flex',
        'flex-direction': 'row',
        'justify-content': 'flex-end'
    },
    mainMenuWrapperMain: { width: '100%' },
    menuActionTop: {
        padding: '0 8px 12px',
        display: 'flex'
    },
    menuActionItem: {
        'flex-grow': '1',
        'min-width': '50%',
        padding: '0 4px 4px',
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'stretch'
    },
    menuActionBtn: {
        width: '100%',
        margin: '0'
    },
    mainMenuHamburgerNativeActive: media(width, height, 'w', {
        993: { display: 'none' },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            flex: '0 0 44px',
            height: '44px',
            'border-radius': '12px',
            border: `1px solid ${variable.darkExtraTransparentColor}`,
            left: '16px',
            margin: '0'
        }
    }),
    mainMenuHamburgerNativeActiveMain: media(width, height, 'w', {
        1210: { display: 'none' },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            flex: '0 0 42px',
            height: '42px',
            'border-radius': '50%',
            margin: '0',
            background: variable.mainColorAccent,
            cursor: 'pointer',
            'max-width': 'calc(100% - 44px - 26px)'
        }
    }),
    mainMenuHamburgerNativeActiveMainHover: { background: variable.mainColorAccentHover },
    mainMenuHamburgerNativeDisactive: { display: 'none' },
    mainMenuLogo: media(width, height, 'w', {
        993: {
            display: 'flex',
            'justify-content': 'flex-start',
            order: '-1',
            'align-items': 'center'
        },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'margin': '0 auto',
            'padding-right': '32px',
            'max-width': 'calc(100% - 44px)'
        }
    }),
    mainMenuLogoLink: media(width, height, 'w', {
        993: {
            'max-width': '100%',
            'padding-left': '0'
        },
        0: {
            'max-width': '100%',
            'padding-left': '8px'
        }
    }),
    mainMenuLogoMain: media(width, height, 'w', {
        993: {
            display: 'flex',
            'justify-content': 'flex-start',
            order: '-1',
            'align-items': 'center',
            height: '44px',
            'margin-top': '6px'
        },
        0: {
            height: '44px',
            'margin-top': '6px',
            display: 'flex',
            'align-items': 'center',
            order: '-1',
            flex: '1'
        }
    }),
    mainMenuLogoImg: media(width, height, 'w', {
        1320: {
            width: '118px',
            height: '44px'
        },
        992: {
            'max-width': '100%',
            width: '102px',
            height: '44px',
            display: 'inline-flex'
        },
        0: {
            'max-width': '100%',
            width: '102px',
            height: '44px',
            display: 'inline-flex'
        }

    }),
    mainMenuLogoNative: {
        993: { display: 'none' },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center'
            // 'order': '1'
        }
    },
    mainMenuHamburgerNativeImg: {
        width: '20px',
        height: '20px'
    },
    mainContent: {
        display: 'flex',
        'flex-grow': 1
    },
    wrapAuthBlockWeb: {
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        flex: '1'

    },
    authBlockWeb: media(width, height, 'w', {
        768: {
            background: variable.whiteColor,
            'box-shadow': `0px 30px 60px ${variable.greyShadowColor}`,
            'border-radius': '24px',
            'max-width': '480px',
            width: '100%',
            display: 'flex',
            'flex-direction': 'column',
            padding: '48px 40px',
            margin: '0 auto 0',
            position: 'relative'
        },
        0: {
            position: 'absolute',
            top: '-100%'
        }
    }),
    authBlockWebActive: {
        background: variable.whiteColor,
        'box-shadow': `0px 30px 60px ${variable.greyShadowColor}`,
        'border-radius': '24px',
        width: '100%',
        'flex-direction': 'column',
        'justify-content': 'space-between',
        'flex-grow': '1',
        display: 'flex',
        padding: '32px 16px 16px 16px',
        margin: '0 auto 0'
    },
    authBlockNativeActive: {
        background: variable.whiteColor,
        'box-shadow': `0px 30px 60px ${variable.greyShadowColor}`,
        'border-radius': '24px',
        width: '100%',
        'flex-direction': 'column',
        'justify-content': 'space-between',
        'flex-grow': '1',
        display: 'flex',
        padding: '32px 16px 16px 16px',
        margin: '0 auto 0'
    },
    authBlockNativeNotActive: { display: 'none' },
    authHeading: {
        display: 'flex',
        'flex-direction': 'row',
        'margin-bottom': '24px'
    },
    authHeadingTitle: {
        'font-weight': '500',
        ...uikit.h5,
        color: variable.blueDarkColor,
        'text-align': 'center',
        background: 'transparent',
        flex: 1,
        'margin-bottom': '16px'
    },
    authHeadingText: {
        ...uikit.text,
        color: variable.blueDarkColor,
        'text-align': 'center',
        background: 'transparent'
    },
    wrapButtonMenu: {
        padding: '16px',
        'border-color': variable.greyColor,
        'border-width': '1px',
        'border-style': 'solid',
        'border-left-width': '0',
        'border-right-width': '0',
        'margin-bottom': '24px'
    },
    button: {
        'border-radius': '14px',
        height: '48px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        margin: '8px 0'
    },
    buttonGray: { 'background-color': variable.greyTransparentColor },
    buttonOrange: { 'background-color': variable.orangeColor },
    buttonOrangeHover: { 'background-color': variable.orangeLightColor },
    buttonGrayHover: {
        'background-color': variable.greyColor,
        cursor: 'pointer',
        'user-select': 'none'
    },
    buttonGrayFocus: { 'background-color': variable.greyLightColor },
    buttonOrangeText: {
        'font-weight': '500',
        ...uikit.text,
        color: variable.whiteColor
    },
    wrapLinksMenu: {
        'flex-grow': 1,
        padding: '0',
        background: variable.whiteColor
    },
    mainMenu: media(width, height, 'w', {
        993: {
            display: 'flex',
            // margin: '0 -5px',
            'flex-grow': '1',
            // 'justify-content': 'space-between',
            'flex-direction': 'row',
            position: 'relative',
            'justify-content': 'space-between',
            'margin-bottom': '16px',
            'margin-top': '24px',
            'align-items': 'center'
        },
        0: {
            display: 'flex',
            // margin: '0 -5px',
            'flex-grow': '1',
            // 'justify-content': 'space-between',
            'flex-direction': 'row',
            position: 'relative',
            'justify-content': 'center',
            'margin-bottom': '16px',
            'margin-top': '24px',
            'align-items': 'center'
        }
    }),
    mainMenuItem: media(width, height, 'w', {
        1300: {
            ...uikit.text,
            padding: '0',
            color: variable.darkColor,
            transition: 'color 0.5s',
            'padding-left': '20px',
            'padding-right': '20px'
        },
        1100: {
            ...uikit.text,
            padding: '0',
            color: variable.darkColor,
            transition: 'color 0.5s',
            'padding-left': '12px',
            'padding-right': '12px'
        },
        993: {
            ...uikit.text,
            padding: '0',
            color: variable.darkColor,
            transition: 'color 0.5s',
            'padding-left': '6px',
            'padding-right': '6px'
        },
        0: {
            ...uikit.text,
            'line-height': '1.25',
            'font-weight': '400',
            'min-height': '65px',
            padding: '20px 23px',
            color: variable.darkColor,
            background: variable.whiteColor,
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'align-items': 'center',
            'padding-left': '20px',
            'padding-right': '20px',
            width: '100%'
        }
    }),
    mainMenuItemMain: media(width, height, 'w', {
        1530: { 'padding': '0 30px' },
        1210: { 'padding': '0 10px' },
        0: { 'padding': '0' }
    }),
    menuItemImg: {
        width: '24px',
        'margin-bottom': '0',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'margin-right': '6px'
    },
    itemMenuStyle: media(width, height, 'w', {
        768: {
            color: variable.darkColor,
            'font-size': '16px'
        },
        0: {
            color: variable.darkColor,
            'font-size': '14px'
        }
    }),
    itemMenuStyleMainHeader: media(width, height, 'w', {
        1500: {
            color: 'currentColor',
            'font-family': 'Inter',
            'font-weight': 400,
            'font-size': '16px',
            'line-height': '36px'
        },
        1210: {
            color: 'currentColor',
            'font-family': 'Inter',
            'font-weight': 400,
            'font-size': '16px',
            'line-height': '36px'
        },
        768: {
            color: 'currentColor',
            'font-family': 'Inter',
            'font-weight': 600,
            'font-size': '24px',
            'line-height': '29px',
            'text-align': 'center'
        },
        0: {
            color: 'currentColor',
            'font-family': 'Inter',
            'font-weight': 400,
            'font-size': '18px',
            'line-height': '29px',
            'text-align': 'center'
        }
    }),
    itemMenuStyleMain: {
        color: 'currentColor', //variable.mainColorText,
        'font-family': 'Inter',
        'font-weight': 400,
        'font-size': '18px',
        'line-height': '36px'
    },
    itemMenuStyleHover: {
        opacity: '.85',
        color: variable.calcColor('orange', 1)
    },
    itemMenuStyleMainFooter: {
        'font-family': 'Inter',
        'font-weight': 400,
        'font-size': '16px',
        'line-height': '36px',
        color: variable.darkColor
    },
    itemMenuStyleMainFooterHover: {
        'font-family': 'Inter',
        'font-weight': 400,
        'font-size': '16px',
        'line-height': '36px',
        color: variable.calcColor('orange', 1)
    },
    menuItemTextP: media(width, height, 'w', {
        993: {
            'padding-left': '0',
            color: variable.darkHalfTransparentColor,
            'font-weight': '500',
            ...uikit.small,
            position: 'relative',
            'flex-grow': 1
        },
        0: {
            'padding-left': '23px',
            color: variable.darkColor
        }
    }),
    menuItemTextHover: { color: variable.blueColor },
    menuFooter: media(width, height, 'w', {
        993: { display: 'none' },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'flex-end',
            'align-items': 'center',
            'background-color': variable.whiteColor,
            padding: '16px 12px 8px',
            'text-align': 'center',
            'font-size': '0.625rem',
            'line-height': '1.2',
            color: variable.darkColor
        }
    }),
    menuFooterText: { 'margin-bottom': '4px' },
    menuFooterDecorElement: {
        display: 'inline-block',
        width: '134px',
        height: '5px',
        'margin-top': '17px'
    },
    //    App btn
    buttonAppSvg: media(width, height, 'w', {
        993: {
            width: '28px',
            height: '31px'
        },
        0: {
            'width': '24px',
            'height': '29px'
        }
    }),
    btnApp: media(width, height, 'w', {
        993: { display: 'none' },
        0: {
            padding: '10px 16px',
            'border-radius': '14px',
            display: 'flex',
            'background-color': 'transparent',
            outline: 'transparent',
            position: 'relative',
            'flex-direction': 'row',
            'justify-content': 'center',
            'font-weight': '500',
            flex: 1,
            color: variable.darkColor,
            'max-height': '56px',
            'max-width': '170px'
        }
    }),
    btnAppNative: {
        padding: '8px 8px 8px 10px',
        'border-radius': '10px',
        display: 'flex',
        'align-items': 'center',
        'background-color': 'transparent',
        position: 'relative',
        flex: 1,
        'flex-direction': 'row'
    },
    appWrapperButtons: media(width, height, 'w', {
        993: { display: 'none' },
        0: {
            display: 'flex',
            padding: '16px 12px 0',
            'align-items': 'center',
            'justify-content': 'center',
            'background-color': variable.whiteColor
        }
    }),
    appButtonText: {
        color: 'inherit',
        ...uikit.small,
        'line-height': '1.28'
    },
    appButtonTextNative: {
        color: variable.blackColor,
        'font-size': 14
    },
    appButtonTextSm: {
        color: 'inherit',
        'font-size': '0.625rem',
        'line-height': '1.6'
    },
    appButtonTextSmNative: {
        color: variable.blackColor,
        'font-size': 10
    },

    //    END App btn
    mainMenuItemHover: {
        cursor: 'pointer',
        color: variable.blueColor,
        opacity: '0.5'
    },
    imgBackground: {
        width: '100%',
        flex: 1,
        display: 'flex',
        'flex-direction': 'column',
        position: 'relative'
    },
    webMenuBlock: media(width, height, 'w', {
        993: {
            display: 'flex',
            'align-items': 'stretch'
        },
        0: { display: 'none' }
    }),
    webMenuBlockMain: media(width, height, 'w', {
        1210: {
            display: 'flex',
            'align-items': 'stretch'
        },
        0: { display: 'none' }
    }),
    footerBlockWeb: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'margin-top': '32px',
            'margin-bottom': '32px'
        },
        0: { display: 'none' }
    }),
    footerBlockNative: { display: 'none' },
    footerText: {
        color: variable.whiteColor,
        ...uikit.small
    },
    NativeMenuBlock: {
        position: 'absolute',
        top: '0',
        left: '-100%',
        width: '100%',
        height: '100%',
        display: 'flex',
        color: variable.whiteColor,
        'z-index': '10',
        'background-color': variable.blackTransparentColor
    },
    NativeMenu: {
        display: 'flex',
        'flex-direction': 'column',
        width: '250px',
        'border-radius': '5px',
        padding: ' 15px',
        left: '0',
        top: '0',
        'background-color': variable.whiteColor,
        height: '100%'
    },
    WebMenu: media(width, height, 'w', {
        993: { display: 'none' },
        425: {
            display: 'flex',
            'flex-direction': 'column',
            left: '0',
            top: '0',
            'background': variable.mainWrapperMenuBackground,
            height: '100vh',
            'box-shadow': `0px 5px 20px ${variable.blackExtraTransparentColor}`,
            'border-radius': '0',
            width: '375px'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            left: '0',
            top: '0',
            'background': variable.mainWrapperMenuBackground,
            height: '100vh',
            'box-shadow': `0px 5px 20px ${variable.blackExtraTransparentColor}`,
            'border-radius': '0',
            width: '100%'
        }
    }),
    WebMenuMain: media(width, height, 'w', {
        1210: { display: 'none' },
        425: {
            padding: '12px 20px 86px 20px',
            display: 'flex',
            'flex-direction': 'column',
            //left: '0',
            right: 0,
            top: '0',
            height: '100vh',
            'border-radius': '0',
            background: '#fff',
            width: '100vw',
            'margin-left': 'auto'
            //width: '390px'
        },
        0: {
            padding: '12px 20px 86px 20px',
            display: 'flex',
            'flex-direction': 'column',
            //left: '0',
            right: 0,
            top: '0',
            height: '100vh',
            background: '#fff',
            'border-radius': '0',
            width: '100%'
        }
    }),
    widgetUser: {
        display: 'flex',
        'align-items': 'center',
        cursor: 'pointer',
        position: 'relative',
        'z-index': '1'
    },
    singleImage: {
        width: '38px',
        height: '38px',
        border: `2px solid ${variable.greyColor}`,
        'border-radius': '50%',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        margin: '0 12px'
    },
    singleImageMain: {
        width: '80px',
        height: '80px',
        border: `2px solid ${variable.greyColor}`,
        'border-radius': '50%',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        margin: '0 12px'
    },
    singleImageItem: {
        'border-radius': '50%',
        width: '34px',
        height: '34px'
    },
    singleImageItemMain: {
        'border-radius': '50%',
        width: '34px',
        height: '34px'
    },
    AuthMenu: {
        padding: '24px 0',
        'border-radius': '0',
        'border-top-right-radius': '20px',
        width: '278px'
    },
    formLinkText: {
        color: variable.whiteColor,
        ...uikit.small,
        'font-weight': '500'
    },
    marginLeft24: { ...uikit.ml4 },
    dFlex: { display: 'flex' },
    buttonYellow: {
        'background-color': variable.orangeColor,
        transition: 'all ease .25s',
        color: variable.whiteColor
    },
    buttonYellowHover: {
        'background-color': variable.orangeDarkColor,
        color: variable.whiteColor,
        transition: 'all ease .25s'
    },
    buttonTransparentOutline: {
        background: 'transparent',
        color: variable.whiteColor,
        border: `2px solid ${variable.whiteLessTransparentColor}`,
        transition: 'all ease .25s'
    },
    buttonTransparentOutlineHover: {
        background: variable.whiteColor,
        color: variable.blueColor,
        border: `2px solid ${variable.whiteColor}`,
        transition: 'all ease .25s'
    },
    buttonWhite: {
        background: variable.whiteColor,
        color: variable.blueColor,
        transition: 'all ease .25s'
    },
    buttonWhiteHover: {
        background: variable.whiteColor,
        color: variable.blueColor,
        transition: 'all ease .25s'
    },
    justifyContentBetween: { 'justify-content': 'space-between' },
    enterButton: media(width, height, 'w', {
        1536: {
            'font-weight': '500',
            'min-width': '113px',
            'min-height': '60px',
            ...uikit.text,
            display: 'inline-flex',
            'align-items': 'center',
            'flex-direction': 'column',
            'justify-content': 'center',
            cursor: 'pointer',
            'border-radius': '14px',
            'margin-right': '24px'
        },
        0: {
            'font-weight': '500',
            'min-width': '113px',
            'min-height': '60px',
            ...uikit.text,
            display: 'inline-flex',
            'align-items': 'center',
            'flex-direction': 'column',
            'justify-content': 'center',
            cursor: 'pointer',
            'border-radius': '14px',
            'margin-right': '18px'
        }
    }),
    menuCloseBtn: {
        width: '44px',
        height: '44px',
        'flex-shrink': '0',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        background: variable.whiteLessTransparentColor,
        'border-radius': '12px'
    },
    menuCloseBtnIcon: { fill: variable.whiteColor },
    menuTitle: media(width, height, 'w', {
        993: { display: ' none' },
        0: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'margin-top': '0',
            'align-items': 'center',
            'padding': '10px 12px 16px'
        }
    }),
    menuTitleLogo: {
        'margin-right': '44px',
        flex: 'auto',
        width: 'auto',
        height: '24px'
    },
    menuTitleLogoIcon: { fill: variable.whiteColor },
    menuScroll: media(width, height, 'w', {
        1210: {
            display: 'block',
            'overflow-y': 'visible'
        },
        0: {
            'overflow-y': 'auto',
            'flex-grow': '1',
            'flex-shrink': '1',
            display: 'flex',
            'flex-direction': 'column'
        }
    }),
    menuWrapper: media(width, height, 'w', {
        993: {
            display: 'block',
            position: 'relative',
            'border-radius': '0',
            overflow: 'visible'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            position: 'relative',
            'border-radius': '20px 20px 0 0',
            overflow: 'hidden',
            'flex-grow': '1',
            'flex-shrink': '0'
        }
    }),
    menuLangSwitcher: media(width, height, 'w', {
        0: {
            'min-height': '65px',
            'border-bottom': `1px solid ${variable.darkSuperTransparentColor}`,
            padding: '16px 23px',
            color: variable.darkColor,
            background: variable.whiteColor,
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'align-items': 'center'
        }
    }),
    menuLangSwitcherLangFlag: {
        'flex-grow': '0',
        'flex-shrink': '0',
        width: '24px',
        height: '24px',
        'border-radius': '50%',
        background: variable.greyColor,
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        overflow: 'hidden'
    },
    menuLangSwitcherLangFlagImg: {
        width: '24px',
        height: '24px'
    },
    menuLangSwitcherInfo: { padding: '0 0 0 20px' },
    menuLangSwitcherTitle: {
        ...uikit.tiny,
        'line-height': '1.16',
        color: variable.greySuperDarkColor,
        margin: '0 0 2px'
    },
    menuLangSwitcherName: {
        ...uikit.small,
        'line-height': '1.14'
    },
    langSwitcher: media(width, height, 'w', {
        992: { width: '74px' },
        0: { width: '44px' }
    }),
    headerSticky: media(width, height, 'w', {
        768: {
            position: 'fixed',
            top: '0px',
            'z-index': 999
        },
        0: {
            position: 'fixed',
            top: '0px',
            'z-index': 9
        }
    }),
    bgcWhite: { 'background-color': variable.whiteColor },
    fullWidth: { width: '100%' },

    footer: media(width, height, 'w', {
        1536: {
            display: 'flex',
            'justify-content': 'space-between',
            padding: '16px 0'
        },
        576: {
            display: 'flex',
            'justify-content': 'space-between',
            padding: '16px 30px'
        },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'flex-direction': 'column',
            padding: '16px',
            'max-width': '1504px'
        }
    }),
    copyright: media(width, height, 'w', {
        768: {
            'user-select': 'none',
            'font-weight': 'normal',
            ...uikit.small,
            color: variable.greyExtraDarkColor
        },
        0: {
            'font-weight': 'normal',
            ...uikit.small,
            color: variable.greyExtraDarkColor
        }
    }),
    dev: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center'
        },
        0: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center'
        }
    }),
    devText: media(width, height, 'w', {
        768: {
            'user-select': 'none',
            ...uikit.small,
            color: variable.greyExtraDarkColor,
            'margin-right': '4px'
        },
        0: {
            ...uikit.small,
            color: variable.greyExtraDarkColor,
            'margin-right': '4px'
        }
    }),
    devLink: {
        ...uikit.small,
        color: variable.blueColor,
        'font-weight': '500'
    },
    devLinkHover: { 'text-decoration': 'underline' },
    cursorPointer: { ...uikit.pointer },
    centered: {
        'margin-left': 'auto',
        'margin-right': 'auto'
    },
    // Lang Modal
    modalContent: media(width, height, 'w', {
        0: {
            width: '100%',
            height: '100%',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'flex-end'
        }
    }),
    modalBody: media(width, height, 'w', {
        425: {
            width: '100%',
            background: variable.whiteColor,
            padding: '16px 12px 8px',
            'border-radius': '20px 20px 0px 0px',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        },
        0: {
            width: '100%',
            background: variable.whiteColor,
            padding: '16px 12px 8px',
            'border-radius': '20px 20px 0px 0px',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        }
    }),
    modalTopDecor: {
        width: '32px',
        height: '4px',
        background: variable.greyExtraLightColor,
        'border-radius': '100px',
        margin: '0 0 20px'
    },
    modalBottomDecor: {
        width: '134px',
        height: '4px',
        margin: '21px 0 0'
    },
    modalTitle: {
        'font-weight': 500,
        ...uikit.subtitle
    },
    modalLangListWrapper: {
        width: '100%',
        overflow: 'hidden'
    },
    modalLangList: { margin: '-3px 0 -1px' },
    modalLangListItem: {
        'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`,
        padding: '16px 0',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center'
    },


    //sas
    langSwitcherRight: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'center'
        },
        0: {
            display: 'none',
            'align-items': 'center'
        }
    }),
    menuLangSwitcherDesktop: {
        margin: '0 30px',
        width: '40px',
        'min-width': '40px',
        height: '40px',
        border: `1px solid ${variable.greyExtraDarkColorTransparent}`,
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'border-radius': '12px',
        'cursor': 'pointer'
    },
    menuLangSwitcherFlag: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
    },
    menuLangSwitcherLangFlagImgDesktop: {
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px'
    },
    menuLangSwitcherNameDesktop: {
        'text-transform': 'capitalize',
        ...uikit.small
    },
    menuLangsWrapper: {
        position: 'relative',
        'z-index': '1'
    },
    menuLangsBackdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'transparent',
        'z-index': '-2'

    },
    modalLangListDesktop: {
        'overflow': 'hidden',
        'border-radius': '14px'
    },
    modalLangListDesktopMain: {
        position: 'absolute',
        top: '55px',
        background: '#fff',
        right: 0,
        left: '90px',
        'box-shadow': '0px 0px 10px rgba(0, 0, 0, 0.15)',
        'overflow': 'hidden'
    },
    modalLangListArrow: {
        position: 'absolute',
        top: '-12px',
        height: '14px',
        left: '50%',
        width: '33px',
        transform: 'translateX(-50%)',
        'z-index': '-1',
        fill: variable.whiteColor
    },
    modalLangListWrap: {
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        'background': variable.whiteColor,
        'border-radius': '14px',
        'margin-top': '14px',
        'box-shadow': `0 0 10px ${variable.greyExtraDarkMHalfTransparentColor}`
    },
    modalUserListWrap: { left: '32px' },
    modalLangListItemDesktop: {
        display: 'flex',
        'align-items': 'center',
        padding: '12px',
        'border-top': `1px solid ${variable.darkTransparentColor}`,
        'cursor': 'pointer',
        fill: variable.blueTotalGray
    },
    modalLangListItemFirst: { 'border-top': 'none' },
    modalLangListItemHover: { 'background': `${variable.greyExtraDarkTransparentColor}` },
    menuLangSwitcherListFlag: {
        'margin-right': '10px',
        background: variable.greyExtraLightColor,
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px'
    },
    menuIconUser: {
        'margin-right': '10px',
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px',
        fill: 'inherit'
    },
    menuIconUserHover: {
        'background': variable.greyExtraDarkTransparentColor,
        fill: variable.blueColor,
        color: variable.blueColor
    },
    menuLangSwitcherInfoDesktop: {
        ...uikit.small,
        'white-space': 'pre'
    },
    menuLangSwitcherInfoDesktopMain: {
        'font-weight': 400,
        'font-size': '16px',
        'line-height': '19px',
        color: '#231F20',
        'white-space': 'pre',
        cursor: 'pointer'
    },
    webMenuBlockButtons: {
        'margin-left': '20px',
        display: 'flex',
        'align-items': 'center'
    },
    webMenuBlockButtonsMain: media(width, height, 'w', {
        1530: {
            'margin': '0 -12px',
            display: 'flex',
            'align-items': 'center',
            'padding-left': '30px'
        },
        0: {
            'margin': '0 -12px',
            display: 'flex',
            'align-items': 'center',
            'padding-left': '10px'
        }
    }),

    webMenuBlockButtonsMainItem: { padding: '0 12px' },
    mainMenuButtonHover: { opacity: '0.6' },
    mainMenuButton: {
        'cursor': 'pointer',
        'font-weight': '500',
        padding: '0 12px',
        color: variable.darkColor,
        'border-left': `1px solid ${variable.darkExtraTransparentColor}`
    },

    mainMenuBtn: media(width, height, 'w', {
        1210: {
            display: 'inline-flex',
            'align-items': 'center',
            'justify-content': 'center',
            'cursor': 'pointer',
            'font-family': 'Inter',
            'font-weight': 400,
            'line-height': '1.1875',
            'border-radius': '42px',
            padding: '14px 20px',
            transition: '.3s'
        },
        0: {
            display: 'inline-flex',
            'align-items': 'center',
            'justify-content': 'center',
            'cursor': 'pointer',
            'font-family': 'Inter',
            'font-weight': 400,
            'line-height': '1.1875',
            'border-radius': '42px',
            padding: '16px 20px',
            transition: '.3s'
        }
    }),

    mainMenuBtnFooter: media(width, height, 'w', {
        993: { padding: '18px 32px' },
        0: { padding: '18px 32px' }
    }),
    mainMenuBtnSuccess: {
        background: variable.mainColorAccent,
        color: '#fff',
        'border': `1px solid ${variable.mainColorAccent}`
    },
    mainMenuBtnSuccessHover: {
        background: variable.mainColorAccentHover,
        'border': `1px solid ${variable.mainColorAccentHover}`
    },
    mainMenuBtnPrimary: {
        background: variable.mainColorAccentHover,
        color: '#fff',
        'border': `1px solid ${variable.mainColorAccentHover}`
    },
    mainMenuBtnPrimaryHover: {
        background: variable.mainColorAccent,
        'border': `1px solid ${variable.mainColorAccent}`
    },
    mainMenuBtnOutline: {
        color: variable.mainColorText,
        'border': `1px solid ${variable.mainColorAccent}`
    },
    mainMenuBtnOutlineHover: {
        color: '#fff',
        background: variable.mainColorAccent,
        'border': `1px solid ${variable.mainColorAccent}`
    },
    mainMenuButtonFirst: { 'border-left': 'none' },
    authWrapper: {
        'background-color': variable.whiteColor,
        padding: '24px 20px 0px 8px',
        border: `1px solid ${variable.darkSuperTransparentColor}`
    },
    authWrapperHeader: {
        display: 'flex',
        'align-items': 'center'
    },
    authWrapperBody: {
        'padding-left': '66px',
        color: variable.blueTotalGray
    },
    authWrapperBodyItem: { 'margin-bottom': '12px' },
    subMenuWrapper: media(width, height, 'w', {
        993: {
            position: 'absolute',
            'z-index': '2',
            'width': '204px',
            background: variable.whiteColor,
            padding: '8px',
            'border-radius': '14px',
            filter: `drop-shadow(0px 10px 100px ${variable.blackTransparentColor})`,
            left: '50%',
            top: 'calc(100%)',
            transform: 'translateX(-50%)'
        },
        0: {

            'width': '100%',
            background: variable.whiteColor,
            'padding-top': '10px'
        }
    }),
    singleSubMenuItem: media(width, height, 'w', {
        993: {
            'border-radius': '8px',
            padding: '8px'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            'border-radius': '8px',
            'padding-left': '41px',
            'padding-bottom': '20px'
        }
    }),
    officeMenuItemText: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-between',
        'flex-grow': '1'
    },
    officeMenuItemTextMain: media(width, height, 'w', {
        1210: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-between',
            'flex-grow': '1'
        },
        0: {
            display: 'flex',
            ...uikit.alignItemsCenter,
            'text-align': 'left',
            ...uikit.justifyContentStart,
            'flex-grow': '1',
            'margin-bottom': '50px',
            'padding-left': '23px'
        }
    }),
    officeMenuItemTextP: media(width, height, 'w', {
        992: {
            color: variable.mainColorText,
            'font-size': '14px',
            'line-height': '18px'
        },
        0: {
            color: variable.mainColorText,
            'font-size': '14px',
            'line-height': '18px'
        }
    }),
    officeMenuItemTextHover: { color: variable.orangeColor },
    adminSubMenuActive: { background: variable.greyExtraDarkTransparentColor },
    singleSubMenuItemText: {
        ...uikit.text,
        color: variable.darkColor,
        cursor: 'pointer'
    },
    singleSubMenuActiveText: { color: variable.blueColor },
    wrapperItem: media(width, height, 'w', {
        993: {
            position: 'relative',
            ...uikit.flexRow,
            ...uikit.alignItemsCenter,
            ...uikit.justifyContentCenter,
            cursor: 'pointer'
        },
        0: {
            'border-bottom': `1px solid ${variable.darkSuperTransparentColor}`,
            position: 'relative',
            ...uikit.flexRow,
            ...uikit.alignItemsCenter,
            ...uikit.justifyContentCenter,
            cursor: 'pointer'
        }
    }),
    wrapperItemMain: media(width, height, 'w', {
        1210: {
            position: 'relative',
            ...uikit.flexRow,
            ...uikit.alignItemsCenter,
            ...uikit.justifyContentCenter,
            cursor: 'pointer'
        },
        0: {
            position: 'relative',
            ...uikit.flexRow,
            ...uikit.alignItemsStart,
            ...uikit.justifyContentCenter,
            cursor: 'pointer'
        }
    }),
    singleWrapper: media(width, height, 'w', {
        0: {
            ...uikit.flexColumn,
            'min-height': '100%'
        }
    }),
    footerWrap: media(width, height, 'w', {
        768: {
            background: '#F9F9F9',
            'border-radius': '0px 60px 0px 0px',
            'font-family': 'Inter',
            'font-weight': 300,
            'padding': '45px 16px 40px',
            'z-index': '1'
        },
        0: {
            background: '#F9F9F9',
            'border-radius': '0px 60px 0px 0px',
            'font-family': 'Inter',
            'font-weight': 300,
            padding: '55px 0 16px 0',
            'z-index': '1'
        }
    }),
    footerDown: media(width, height, 'w', {
        1700: {
            'background-color': variable.whiteColor,
            'border-top': `1px solid ${variable.darkExtraTransparentColor}`
        },
        1024: {
            'background-color': variable.whiteColor,
            'border-top': `1px solid ${variable.darkExtraTransparentColor}`
        },
        0: { 'background-color': variable.whiteColor }
    }),
    footerLinkMobile: media(width, height, 'w', {
        1700: {
            'background-color': variable.blueColor,
            height: '80px',
            'margin-top': '96px'
        },
        1024: {
            'background-color': variable.blueColor,
            height: '80px',
            'margin-top': '96px',
            'padding': '0 16px'
        },
        768: {
            'background-color': variable.blueColor,
            height: '140px',
            'margin-top': '36px',
            'padding': '0 16px'
        },
        0: {
            width: '100%',
            'background-color': variable.blueColor,
            height: '310px',
            'border-radius': '20px 20px 0px 0px'
            // 'margin-top': '100px',
            // 'padding': '0 32px'
        }
    }),
    footerLinkMobileWrapper: media(width, height, 'w', {
        1700: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            height: '100%'
        },
        768: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            height: '100%'
        },
        0: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            height: '100%',
            'flex-direction': 'column'
        }
    }),
    footerLinkMobileImage: media(width, height, 'w', {
        992: {
            width: '320px',
            position: 'relative',
            height: '100%',
            order: 1
        },
        768: {
            width: '470px',
            position: 'relative',
            height: '100%',
            order: 1
        },
        0: {
            width: '320px',
            position: 'relative',
            height: '100%',
            order: 3
        }
    }),
    footerImage: {
        position: 'absolute',
        bottom: 0,
        'height': '175px'
    },
    footerImageItem: { height: '100%' },
    footerLinkMobileText: media(width, height, 'w', {
        768: {
            color: variable.whiteColor,
            'font-weight': 500,
            'font-size': '1.375rem',
            order: 2
        },
        0: {
            color: variable.whiteColor,
            'font-weight': 500,
            'font-size': '1.375rem',
            'margin-top': '24px',
            'margin-bottom': '16px',
            order: 1
        }
    }),
    footerLinkMobileButtons: media(width, height, 'w', {
        1024: {
            display: 'flex',
            'align-items': 'center',
            order: 2
        },
        768: {
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center',
            order: 2
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            order: 1
        }
    }),
    footerMobileStore: media(width, height, 'w', {
        992: {
            padding: '9px 16px',
            'border-radius': '14px',
            'background-color': variable.whiteColor,
            'font-size': '0.625rem',
            display: 'flex',
            'align-items': 'center',
            cursor: 'pointer'
        },
        768: {
            padding: '9px 16px',
            'border-radius': '14px',
            'background-color': variable.whiteColor,
            'font-size': '0.625rem',
            display: 'flex',
            'align-items': 'center',
            cursor: 'pointer',
            'margin-bottom': '8px'
        },
        0: {
            padding: '9px 16px',
            'border-radius': '14px',
            'background-color': variable.whiteColor,
            'font-size': '0.625rem',
            display: 'flex',
            'align-items': 'center',
            cursor: 'pointer'
        }
    }),
    footerMobileStoreHover: {
        color: variable.blueColor,
        fill: variable.blueColor
    },
    footerInfo: media(width, height, 'w', {
        1700: {
            display: 'flex',
            'justify-content': 'space-between',
            padding: '24px 0'
        },
        1250: {
            display: 'flex',
            'justify-content': 'space-between',
            padding: '24px 12px'
        },
        768: {
            width: '100%',
            display: 'flex',
            'flex-direction': 'row',
            padding: '12px',
            'flex-wrap': 'wrap',
            'justify-content': 'space-between'
        },
        0: {
            width: '100%',
            display: 'flex',
            'flex-direction': 'column',
            padding: '12px 0'
        }
    }),
    footerInfoContact: media(width, height, 'w', {
        1700: { width: '230px' },
        768: { width: '230px' },
        0: {
            width: '100%',
            'text-align': 'center',
            'padding-bottom': '16px',
            'border-bottom': `1px solid ${variable.darkSuperTransparentColor}`
        }
    }),
    footerInfoContactMain: media(width, height, 'w', {
        768: { 'margin-top': '-4px' },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'text-align': 'center',
            'margin-top': '40px'
        }
    }),
    footerMobMenuItemWrap: media(width, height, 'w', {
        768: {},
        0: {
            'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`,
            'padding-right': '12px'
        }
    }),
    footerMenuItemWrapper: media(width, height, 'w', {
        1250: {
            'margin-right': '35px',
            'max-width': '239px',
            'min-width': '70px'
        },
        768: {
            'margin-right': '45px',
            'max-width': '200px',
            'min-width': '70px',
            margin: '10px 45px 10px 0px'
        }
    }),
    footerInfoMainCenter: media(width, height, 'w', {
        1400: {
            display: 'flex',
            'justify-content': 'space-around',
            flex: 0.94
            //margin: '0 -24px'
        },
        1200: {
            display: 'flex',
            margin: '0 -20px'
        },
        0: {}
    }),
    footerMenuItemWrapperMain: media(width, height, 'w', {
        1400: {
            padding: '0 24px'
            // flex: '0 0 33.33333%',
            // 'max-width': '33.33333%'
        },
        1200: {
            padding: '0 20px',
            flex: '0 0 33.33333%',
            'max-width': '33.33333%'
        },
        768: {},
        0: { 'text-align': 'center' }
    }),
    footerMenuItemWrapperMainFirst: media(width, height, 'w', {
        1400: {
            padding: '0 24px'
            // flex: '0 0 33.33333%',
            // 'max-width': '33.33333%'
        },
        1200: {
            padding: '0 20px',
            flex: '0 0 33.33333%',
            'max-width': '33.33333%'
        },
        768: {},
        0: { 'text-align': 'center' }
    }),
    footerInfoPhone: {
        ...uikit.h5,
        'font-weight': 500,
        color: variable.blueColor,
        'margin-bottom': '11px'
    },
    footerInfoPhoneMain: {
        'margin-left': '8px',
        'font-weight': 600,
        'font-size': '16px',
        'line-height': '28px',
        color: variable.mainColorText
    },
    footerInfoPhoneMainHover: { color: variable.orangeColor },
    footerInfoHover: { color: 'rgba(158, 138, 106, 1)' },
    footerInfoTime: {
        ...uikit.small,
        'margin-bottom': '11px'
    },
    footerInfoSubtitle: {
        ...uikit.tiny,
        color: variable.greyExtraDarkColor
    },
    footerSocialTitle: {
        ...uikit.title,
        'margin-bottom': '16px'
    },
    footerSocialTitleMain: media(width, height, 'w', {
        768: {
            'font-weight': 400,
            'font-size': '16px',
            'line-height': '20px',
            'text-align': 'center',
            'margin-bottom': '10px'
        },
        0: {
            'font-weight': 400,
            'font-size': '16px',
            'line-height': '20px',
            'margin-bottom': '16px',
            'text-align': 'center'
        }
    }),
    footerPaymentLogo: { margin: '5px auto' },
    footerPaymentLogoImg: { 'max-width': '180px' },
    footerSocialMainContact: media(width, height, 'w', {
        1210: {
            'text-align': 'center',
            'margin-top': '12px'
        },
        0: {
            'text-align': 'center',
            'margin-top': '24px'
        }
    }),
    footerAccordionTitle: {
        ...uikit.title,
        'margin-bottom': '16px'
    },
    footerAccordionTitleMob: media(width, height, 'w', {
        768: {
            'padding': '14px 0 ',
            ...uikit.small,
            'margin-left': '12px'
        },
        0: {
            ...uikit.small,
            'padding': '2px 0',
            'margin-left': '12px'
        }
    }),
    footerAccordionBodyItem: media(width, height, 'w', {
        768: {
            color: variable.blueTotalGray,
            ...uikit.small,
            'margin-bottom': '12px',
            transition: variable.transition,
            cursor: 'pointer'
        },
        0: {
            color: variable.blueTotalGray,
            ...uikit.small,
            'margin-bottom': '12px',
            transition: variable.transition,
            cursor: 'pointer',
            'margin-left': '12px'
        }
    }),
    footerInfoBody: media(width, height, 'w', {
        1700: {
            display: 'flex',
            'flex-wrap': 'wrap'
        },
        768: {
            display: 'flex',
            'flex-wrap': 'wrap'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column'
        }
    }),
    footerAccordionBodyItemHover: { color: variable.blueColor },
    singleFooterSocialWrapper: media(width, height, 'w', {
        768: {},
        0: {
            'margin-top': '14px',
            'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`,
            'padding': '0px 12px 16px 12px'
        }
    }),
    singleFooterSocialWrapperMain: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            // 'align-items': 'flex-end',
            'margin-top': '8px'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'margin-top': '40px'
        }
    }),
    footerSocial: {
        display: 'flex',
        'align-items': 'center'
    },
    footerSocialMain: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'center',
            margin: '0 -6px',
            'align-self': 'center'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            margin: '0 -6px',
            'align-self': 'end',
            width: '100%',
            'justify-content': 'center'
        }
    }),
    footerSocialIconMainItem: { padding: '0 6px' },
    footerSocialIcon: {
        width: '48px',
        height: '48px',
        'border-radius': '50%',
        'margin-right': '8px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'cursor': 'pointer'
    },
    footerSocialIconHover: { opacity: 0.8 },
    footerSocialIconMain: {
        width: '32px',
        height: '32px',
        'border-radius': '50%',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'cursor': 'pointer',
        border: `1px solid ${variable.mainColorAccent}`
    },
    footerSocialIconMainHover: { border: `1px solid ${variable.mainColorAccentHover}` },
    submenuOverlay: media(width, height, 'w', {
        993: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            'z-index': 1
        },
        0: { display: 'none' }
    }),
    officeMenuItemArrowMob: media(width, height, 'w', {
        768: { display: 'none' },
        0: {
            width: '10px',
            height: '10px',
            fill: variable.darkHalfTransparentColor,
            'margin-left': '15px',
            'margin-right': '14px'
        }
    }),
    officeMenuItemArrowMobRotate: { transform: 'rotate(90deg)' },

    mainMenuWrap: media(width, height, 'w', {
        0: {
            display: 'flex',
            'justify-content': 'space-between',
            width: '100%'
        }
    }),

    mainMenuRight: media(width, height, 'w', {
        1210: { display: 'flex' },
        0: { display: 'none' }
    }),

    cartWidgetWrapMain: { position: 'relative' },

    mainWidgetCart: media(width, height, 'w', {
        1530: {
            display: 'flex',
            'align-items': 'center',
            'padding-left': '30px'
        },
        1210: {
            display: 'flex',
            'align-items': 'center',
            'padding-left': '10px'
        },
        0: {
            display: 'flex',
            'align-items': 'flex-center',
            'padding-left': '23px',
            'justify-content': 'flex-start'
        }
    }),
    cartIconMain: media(width, height, 'w', {
        1210: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            position: 'relative',
            'cursor': 'pointer',
            width: '52px',
            height: '52px',
            'border-radius': '50%',
            background: variable.mainColorAccentHover,
            transition: '.3s'
        },
        0: {
            'font-family': 'Inter',
            'font-weight': 600,
            'font-size': '24px',
            'line-height': '29px',
            'text-align': 'center',
            color: '#231F20'
        }
    }),
    cartIconMainHover: media(width, height, 'w', {
        1210: { background: variable.mainColorAccent },
        0: {}
    }),
    cartIconItemMain: media(width, height, 'w', {
        1210: {
            position: 'absolute',
            width: '21px',
            height: '21px',
            top: '-5px',
            right: '-5px',
            'border-radius': '50%',
            background: variable.mainColorAccent,
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'line-height': '19px'
        },
        0: {
            position: 'absolute',
            width: '20px',
            height: '20px',
            top: '-10px',
            right: '-24px',
            'border-radius': '50%',
            background: variable.mainColorAccentHover,
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'font-weight': 500,
            'font-size': '12px',
            'line-height': '15px',
            color: '#fff'
        }
    }),
    mainMenuLogoMainPageFooter: { 'margin-bottom': '20px', 'margin-top': '10px' },
    footerInfoMainWrap: media(width, height, 'w', {
        768: {
            // display: 'grid',
            // 'grid-template-columns': '1fr 1fr 1fr',
            // 'grid-gap': '16px'
            ...uikit.flexRow,
            ...uikit.justifyContentBetween
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        }
    }),
    footerInfoMainWrapBottom: media(width, height, 'w', {
        768: {
            display: 'flex',
            'justify-content': 'space-between',
            'flex-wrap': 'wrap'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        }
    }),
    footerInfoMainBottom: media(width, height, 'w', {
        1210: {
            'font-weight': 400,
            'font-size': '14px',
            'line-height': '18px',
            'margin-top': '44px',
            color: '#80828C'
        },
        0: {
            'font-weight': 400,
            'font-size': '14px',
            'line-height': '18px',
            'margin-top': '18px',
            color: '#80828C'
        }
    }),
    devLinkBottom: { color: variable.mainColorAccent },
    footerAddress: media(width, height, 'w', {
        768: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'font-weight': 400,
            'font-size': '16px',
            'line-height': '28px',
            'margin-bottom': '8px'
        },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'font-weight': 400,
            'font-size': '16px',
            'line-height': '28px',
            'margin-bottom': '16px'
        }
    }),
    footerContactTitle: {
        'font-weight': 400,
        'font-size': '16px',
        'line-height': '28px',
        'margin-bottom': '8px'
    },
    footerAddressText: { 'margin-left': '8px' },
    footerPhoneWrap: {
        display: 'flex',
        'align-items': 'center',
        'margin-bottom': '8px'
    },
    wrapMob: media(width, height, 'w', {
        768: {},
        0: { 'margin-bottom': '16px' }
    }),
    footerMenuLogoImgMain: {
        width: '131px',
        height: '44px',
        'margin-left': '-8px'
    },
    mainHeadTransition: { transition: 'background-color,box-shadow .5s linear' },
    mobMenuBtn: media(width, height, 'w', {
        1210: {},
        0: { padding: '0 1rem' }
    }),
    notificationsQuickWrapper: media(width, height, 'w', {
        768: {
            position: 'fixed',
            top: '24px',
            right: ' 24px',
            width: '100%',
            'max-width': '404px',
            'z-index': '9999'
        },
        0: {
            position: 'fixed',
            top: '24px',
            'z-index': '9999',
            right: '12px',
            width: '404px',
            'max-width': 'calc(100vw - 24px)'
        }
    })
});
export default stylesheets;